import React from "react";

import GetYourFreePass from "../components/GetYourFreePass";
import ClassesIntro from "../components/HomePage/ClassesIntro";
import FlexiblePlan from "../components/HomePage/FlexiblePlan";
import HeroBanner from "../components/HomePage/HeroBanner";
import SharePassion from "../components/HomePage/SharePassion";
import Studios from "../components/HomePage/Studios";
import Layout from "../components/layout/Layout";
import useFetch from "../hooks/useFetch";
import ErrorBoundary from "../utils/ErrorBoundary";

function HomePage() {
  const { data } = useFetch({
    params: "home-page",
    populate:
      "Banner_1.background,banner_2.background,last_banner.background,free_pass.background,text_group_1,list_banner_3.background,introduce,membership.background,membership.classInfo,membership.membershipData,Banner_1.mobileImage",
  });
  return (
    <ErrorBoundary>
      <Layout>
        <HeroBanner
          title={data?.data?.attributes?.Banner_1?.title}
          bannerMobile={
            data?.data?.attributes?.Banner_1?.mobileImage?.data?.attributes?.url
          }
          img={
            data?.data?.attributes?.Banner_1?.background?.data?.attributes?.url
          }
        />
        <div className="bg-[#cee3c4] select-none">
          <div className=" border-l-0 border-r-0 overflow-hidden px-2 py-[30px] relative ">
            <div className="flex  absolute fix top-1/2 transform -translate-y-1/2 bg-[#cee3c4] text-[40px] text-[#4F6E6A]">
              <div className="flex w-[1440px] home-animated bg-[#cee3c4] lg:gap-[100px] gap-8 items-center logo ">
                <span>
                  <p className="subheading whitespace-nowrap !font-[800]">
                    pilates
                  </p>
                </span>
                <span>
                  <p className="subheading whitespace-nowrap !font-[800]">
                    reformer
                  </p>
                </span>
                <span>
                  <p className="subheading whitespace-nowrap !font-[800]">
                    yoga
                  </p>
                </span>

                <span>
                  <p className="subheading whitespace-nowrap !font-[800]">
                    barre
                  </p>
                </span>
                <span>
                  <p className="subheading whitespace-nowrap !font-[800]">
                    pilates
                  </p>
                </span>
                <span>
                  <p className="subheading whitespace-nowrap !font-[800]">
                    yoga
                  </p>
                </span>
                <span>
                  <p className="subheading whitespace-nowrap !font-[800]">
                    reformer
                  </p>
                </span>
                <span>
                  <p className="subheading whitespace-nowrap !font-[800]">
                    yoga
                  </p>
                </span>
                <span>
                  <p className="subheading whitespace-nowrap !font-[800]">
                    barre
                  </p>
                </span>
                <span>
                  <p className="subheading whitespace-nowrap !font-[800]">
                    pilates
                  </p>
                </span>
                <span>
                  <p className="subheading whitespace-nowrap !font-[800]">
                    pilates
                  </p>
                </span>
                <span>
                  <p className="subheading whitespace-nowrap !font-[800]">
                    reformer
                  </p>
                </span>
                <span>
                  <p className="subheading whitespace-nowrap !font-[800]">
                    yoga
                  </p>
                </span>

                <span>
                  <p className="subheading whitespace-nowrap !font-[800]">
                    barre
                  </p>
                </span>
                <span>
                  <p className="subheading whitespace-nowrap !font-[800]">
                    pilates
                  </p>
                </span>
                <span>
                  <p className="subheading whitespace-nowrap !font-[800]">
                    yoga
                  </p>
                </span>
                <span>
                  <p className="subheading whitespace-nowrap !font-[800]">
                    reformer
                  </p>
                </span>
                <span>
                  <p className="subheading whitespace-nowrap !font-[800]">
                    yoga
                  </p>
                </span>
                <span>
                  <p className="subheading whitespace-nowrap !font-[800]">
                    barre
                  </p>
                </span>
                <span>
                  <p className="subheading whitespace-nowrap !font-[800]">
                    pilates
                  </p>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-center items-center lg:py-[82px] py-[30px]  lg:w-[800px] w-[90%] mx-auto gap-[75px]">
          <div className="py-[43px] justify-between flex flex-col ">
            <p className="heading text-center">
              {data?.data?.attributes?.introduce?.title}
            </p>
            <p className="body text-center mt-[54px]">
              {data?.data?.attributes?.introduce?.content}
            </p>
          </div>
        </div>
        {/* Home Page Intro Offer Section */}
        <GetYourFreePass
          color="#CEE3C4"
          bgShape="#CEE3C4"
          title={data?.data?.attributes?.free_pass?.title}
          desc={data?.data?.attributes?.free_pass?.description}
          image={
            data?.data?.attributes?.free_pass?.background?.data?.attributes?.url
          }
          vector={1}
        />
        <ClassesIntro findStudio data={data} />
        <Studios />
        <FlexiblePlan
          color="white"
          backgroundColor="#C5C5FB"
          title={data?.data?.attributes?.membership?.title}
          description={data?.data?.attributes?.membership?.membershipData}
          classInfo={data?.data?.attributes?.membership?.classInfo}
          background={
            data?.data?.attributes?.membership?.background?.data?.attributes
              ?.url
          }
        />
        {/* <SharePassion data={data} /> */}
      </Layout>
    </ErrorBoundary>
  );
}

export default HomePage;
