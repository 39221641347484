import React, { useState, useEffect } from "react";

function TimeTableFilter() {
  const widgetIds = {
    "Glen Waverly": "83525128f8",
    QLD: "b952499d14",
    Tuggeranong: "c852536062",
    "Hamilton North": "f45254bfd1",
  };

  // Initialize selectedWidgetId as an empty string to show "Please select a location" initially
  const [selectedWidgetId, setSelectedWidgetId] = useState("");

  useEffect(() => {
    if (typeof window !== "undefined") {
      const urlParams = new URLSearchParams(window.location.search);
      const initialWidgetId = urlParams.get("location") || "";

      // Only set selectedWidgetId if there is a location parameter in the URL
      if (initialWidgetId) {
        setSelectedWidgetId(initialWidgetId);
      }
    }
  }, []);

  const handleSelectionChange = (event) => {
    const newWidgetId = event.target.value;
    setSelectedWidgetId(newWidgetId);

    if (typeof window !== "undefined") {
      // Update the URL with the selected location as a query parameter
      const newUrl = `${window.location.pathname}?location=${newWidgetId}`;
      window.history.pushState(null, "", newUrl);

      // Reload the page to re-render the widget with the updated data
      setTimeout(() => {
        window.location.reload();
        window.scrollTo(0, 330); // Scroll to top after reloading
      }, 0);
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined" && selectedWidgetId) {
      // Dynamically add the script on the client side only
      const script = document.createElement("script");
      script.src = "https://brandedweb.mindbodyonline.com/embed/widget.js";
      script.async = true;
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script); // Cleanup script on unmount
      };
    }
  }, [selectedWidgetId]);

  if (selectedWidgetId === null) {
    // Avoid rendering on the server to prevent hydration issues
    return null;
  }

  return (
    <div className="max-w-[1300px] mx-auto">
      <div className="my-4">
        <label htmlFor="locationSelect" className="mr-2">
          Select Location:
        </label>
        <select
          id="locationSelect"
          value={selectedWidgetId}
          onChange={handleSelectionChange}
          className="border rounded p-2"
        >
          <option value="">Please select a location</option>
          {Object.entries(widgetIds).map(([location, id]) => (
            <option key={id} value={id}>
              {location}
            </option>
          ))}
        </select>
      </div>

      {selectedWidgetId ? (
        <div>
          <div
            className="mindbody-widget"
            data-widget-type="Schedules"
            data-widget-id={selectedWidgetId}
          ></div>
        </div>
      ) : (
        <p className="text-center mx-auto my-auto pb-[200px] text-[14px] text-[#000000] uppercase ">
          Please select a location for a timetable.
        </p>
      )}
    </div>
  );
}

export default TimeTableFilter;
