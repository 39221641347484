import React, { useState } from "react";
import ButtonNavigate from "../../common/buttons/ButtonNavigate";
import { Link } from "gatsby";
import useFetch from "../../hooks/useFetch";

function ClassesIntro(props) {
  const { findStudio, data } = props;
  const { data: classInfo } = useFetch({ params: 'class-categories', populate: 'image,shape,classes_types' })
  return (
    <div>
      <div className="grid xl:grid-cols-4 grid-cols-2 lg:mt-0 mt-5  ">
        {classInfo?.data?.map((item, index) => (
          <Link state={{ classSelected: item?.attributes?.name }} key={index} to="/classes">
            <div className="group  cursor-pointer">
              <div className="relative">
                <img src={item?.attributes?.image?.data?.attributes?.url} className="w-full " />
                <img
                  src={item?.attributes?.shape?.data?.attributes?.url}
                  className="group-hover w-full absolute  top-0 opacity-0 group-hover:opacity-100 h-full transition-all duration-300"
                />
              </div>
              <p style={{ backgroundColor: item?.attributes?.color?.color }} className={`feature  text-white text-center lg:py-[40px] py-[20px]  text-[36px] tracking-[0.01em] font-bold`}>
                {item?.attributes?.name}
              </p>
            </div>
          </Link>
        ))}
      </div>
      {findStudio && (
        <div className="lg:mt-[87px] mt-[30px] mb-[43px] flex flex-col justify-center items-center text-center lg:gap-[64px] gap-[30px]">
          <p className="heading text-[48px] text-black leading-[46px]  lg:w-[826px] w-[90%] mx-auto">
            {data?.data?.attributes?.text_group_1?.title}
          </p>
          <p className="body text-[18px] text-black lg:w-[799px] w-[90%] mx-auto leading-[23px]">
            {data?.data?.attributes?.text_group_1?.description}
          </p>
          <Link to="/studios">
            <ButtonNavigate labelText="FIND A STUDIO" />
          </Link>
        </div>
      )}
    </div>
  );
}

export default ClassesIntro;
