import React, { useState, useEffect, useRef } from "react";
import Layout from "../components/layout/Layout";
import HeroBanner from "../components/HomePage/HeroBanner";
import background from "../assets/images/work-with-us/shape-background.png";
import RebalanceInputBase from "../common/inputs/RebalanceInputBase";
import ButtonNavigate from "../common/buttons/ButtonNavigate";
import useFetch from "../hooks/useFetch";
import { createReactEditorJS } from "react-editor-js";
import ErrorBoundary from "../utils/ErrorBoundary";
import Active from "../common/buttons/Active";
import DropdownBase from "../common/dropdowns/DropdownBase";
import emailjs from "@emailjs/browser";
import Succes from "../components/Modal/Succes";

function workWithUs() {
  const form = useRef();
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(false);
  const { data } = useFetch({
    params: "work-with-us",
    populate:
      "becomeInstructor,careerOpportunity,careerOpportunity.background,registerForm,registerForm.image,studioInstructor,topBanner,topBanner.image,topBanner.mobileImage",
  });
  const { data: locations } = useFetch({
    params: "studios",
    populate: "state",
  });

  // Conditionally create EditorJS on client side only
  const EditorJS = typeof window !== "undefined" ? createReactEditorJS() : null;

  const workData = data?.data?.attributes;
  const [current, setCurrent] = useState();
  const [EDITOR_JS_TOOLS, setTools] = useState();
  const [expand, setExpand] = useState(false);
  const [userInfo, setUserInfo] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    briefly: "",
    studio: "",
  });

  useEffect(() => {
    if (typeof window !== "undefined") {
      const loadTools = async () => {
        const { EDITOR_JS_TOOLS } = await import("../utils/Tools");
        setTools(EDITOR_JS_TOOLS);
      };
      loadTools();
    }
  }, []);

  const handleChangeValue = (e) => {
    const value = e.target.value;
    setUserInfo((prevState) => ({ ...prevState, [e.target.name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const emptyFields = Object.entries(userInfo).filter(
      ([_, value]) => value === ""
    );
    if (emptyFields.length > 0) {
      const fieldNames = emptyFields.map(([key]) => key).join(", ");
      alert(`The following fields are required: ${fieldNames}`);
    } else {
      setLoading(true);
      emailjs
        .sendForm(
          "service_84obumn",
          "template_28mjie6",
          form.current,
          "5hwhO2_FEalMJBXGA"
        )
        .then(
          () => {
            form.current.reset();
            setUserInfo({
              firstName: "",
              lastName: "",
              email: "",
              phone: "",
              briefly: "",
              studio: "",
            });
            setStatus(true);
            setLoading(false);
          },
          (error) => {
            console.log(error.text);
          }
        );
    }
  };

  return (
    <ErrorBoundary>
      <Layout>
        {workData && (
          <>
            <HeroBanner
              img={workData.topBanner.image.data.attributes.url}
              bannerMobile={
                workData?.topBanner?.mobileImage?.data?.attributes?.url
              }
              title={workData.topBanner.title}
            />

            {/* Other content remains the same */}

            <div className="details-tag-after max-w-[748px] mx-auto my-[64px] work-block">
              {workData.studioInstructor.map((i, idx) => (
                <div
                  key={idx}
                  className="border-b border-b-lemonadeSecondary first:border-t first:border-t-lemonadeSecondary text-black rounded-[5px] py-6 pl-12 pr-6 relative"
                >
                  <svg
                    onClick={() => {
                      setCurrent(idx);
                      setExpand(!expand);
                    }}
                    className="absolute left-6 cursor-pointer"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      className={`${idx === current && expand ? "hidden" : ""}`}
                      d="M9 1V17"
                      stroke="#FFAA5C"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M1 9H17"
                      stroke="#FFAA5C"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <p
                    onClick={() => {
                      setCurrent(idx);
                      setExpand(!expand);
                    }}
                    className="subheading !text-[20px] pl-6 "
                  >
                    {i?.title}
                  </p>
                  <div>
                    {idx === current && expand && (
                      <div className="pl-6">
                        {EditorJS && EDITOR_JS_TOOLS && (
                          <EditorJS
                            readOnly={true}
                            tools={EDITOR_JS_TOOLS}
                            defaultValue={i?.content && JSON.parse(i?.content)}
                          />
                        )}
                        <div
                          onClick={() =>
                            window.open(i?.url, "_blank", "noopener,noreferrer")
                          }
                          className="mt-2"
                        >
                          <Active text="apply now" />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </Layout>
      {status && <Succes open={status} setOpen={setStatus} />}
    </ErrorBoundary>
  );
}

export default workWithUs;
