import React, {
  useEffect,
  useRef,
  useState,
} from 'react';

import bannerDesk from '../assets/images/movement-challenge/banner.jpg';
import footer from '../assets/images/movement-challenge/footer.png';
import ButtonNavigate from '../common/buttons/ButtonNavigate';
import DropdownBase from '../common/dropdowns/DropdownBase';
import RebalanceInputBase from '../common/inputs/RebalanceInputBase';
import Layout from '../components/layout/Layout';
import Succes from '../components/Modal/Succes';
import useFetch from '../hooks/useFetch';
import {
  FranchiseAPI,
  FreePassToStrapiAPI,
} from '../utils/FetchData';

function MovementChallenge() {
      const form = useRef()
    const [loading, setLoading] = useState<boolean>(false)
    const [status, setStatus] = useState<boolean>(false)
    const [userInfo, setUserInfo] = useState({
        venue_id: "1234", first_name: "", last_name: "", email: "", phone: "", interested_in: "Fitness, Personal Training", user_id: "1234", referred_by: "Friend", "Source Group": "Website", city: "",
        "Source Name": "Free Class",
    })

    const { data: locations } = useFetch({ params: 'studios', populate: 'state' })

    // Handle change value input
    const handleChangeValue = (e) => {
        const value = e.target.value
        setUserInfo((prevState) => ({ ...prevState, [e.target.name]: value }))
    }

    // Get state from studio
    useEffect(() => {
        let state = locations?.data?.filter(item => item?.attributes?.title == userInfo.city)?.[0]?.attributes?.state?.data?.attributes?.Name
        setUserInfo({ ...userInfo, state: state, venue_name: userInfo.city })
    }, [userInfo.city])


    // Handle Submit
    const handleSubmit = async () => {
        const emptyFields = Object.entries(userInfo).filter(([key, value]) => value === '');

        if (emptyFields.length > 0) {
            alert(`The complete all fields`);
        } else {
            setLoading(true)
            emailjs.sendForm('service_84obumn', 'template_28mjie6', form.current, '5hwhO2_FEalMJBXGA')
            await FranchiseAPI(userInfo);
            await FreePassToStrapiAPI(userInfo)
            setStatus(true)
            setLoading(false)
        }
    }

    // Cleanup Data after submit
    useEffect(() => {
        if (!status) {
            setUserInfo({
                venue_id: "1234", first_name: "", last_name: "", email: "", phone: "", interested_in: "Fitness, Personal Training", user_id: "1234", referred_by: "Friend", "Source Group": "Website",
                "Source Name": "Free Class", city: '',
            })
        }
    }, [status])

    return (
        <Layout>
            <div className=" w-full mx-auto my-0  ">
                <div className="relative">
                    <img
                        src={bannerDesk}
                        alt="banner"
                        className=" w-[100vw] object-cover object-top md:block hidden lg:h-[688px] h-[518px] overflow-hidden"
                    />
                    <img
                        src='https://rebalance-cms.s3.ap-southeast-2.amazonaws.com/Untitled_design_2023_08_09_T182419_865_5f3d151608.png?updated_at=2023-08-09T08:25:45.694Z'
                        alt="banner"
                        className=" w-[100vw] object-cover object-top md:hidden block lg:h-[688px] h-[518px] overflow-hidden"
                    />
                    <p className="max-w-[820px] w-full text-center md:hidden block mx-auto heading text-white absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 ">
                        21 day movement challenge
                    </p>
                </div>
            </div>
            <div className='py-[30px] px-[16px] max-w-[1430px] mx-auto'>
                <p className='text-center body'>Create new habits & curate a movement routine with our 21 Day Movement Challenge.</p>
                <p className='text-center text-[10px] my-[20px] body'>It takes 21 days to form a new habit that you'll be able to confidently maintain. This <br /> challenge is not you typical 'challenge' but rather a 21 day commitment to yourself.</p>
                <h2 className='text-center my-[30px] heading2'>$49 for 21 days of unlimited movement classes.</h2>
                <p className='text-center my-[20px] body'>Explore our world-class mat pilates, reformer, yoga and barre classes over 21 days, and find <br /> the perfect workout to suit your individual needs. Our holistic approach to movement is all <br /> about balance, enjoying your workout, loving your body and taking a day off when you feel <br /> like it.</p>
                <p className='text-center body'>Simply register your details below & you'll receive an email with a link to join the challenge. <br /> Start your 21 days today!</p>
            </div>
            <div className=" lg:py-[90px] py-[40px] bg-turf xxx:px-[105px] px-5 gap-[42px] justify-between">
                <div className="xxx:w-[1181px] w-full mx-auto flex xxx:flex-row flex-col items-start gap-10 justify-between">
                    <div className="py-3 text-white lg:w-[413px] w-full flex flex-col justify-between">
                        <p style={{ lineHeight: '120%' }} className="heading">
                            Register for the  <br />21 Day Challenge
                        </p>
                    </div>
                    <form ref={form} >

                    <div className="grid xxx:grid-cols-2 grid-cols-1 xxx:w-[724px] w-full col-span-5 gap-x-3 gap-y-6">
                        <RebalanceInputBase value={userInfo.first_name} onChange={handleChangeValue} isWhite nameInput="first_name" holder="First name*" />
                        <RebalanceInputBase value={userInfo.last_name} onChange={handleChangeValue} isWhite nameInput="last_name" holder="Last name*" />
                        <RebalanceInputBase value={userInfo.email} onChange={handleChangeValue} isWhite nameInput="email" holder="Email address*" />
                        <RebalanceInputBase value={userInfo.phone} onChange={handleChangeValue} isWhite nameInput="phone" holder="Phone number*" />
                        <DropdownBase color='white' holder='Studio (Locations)'
                            options={locations?.data}
                            location={userInfo.city}
                            setLocation={selectedLocation => setUserInfo({ ...userInfo, city: selectedLocation })} />
                        <div className="flex justify-end w-full items-center float-right ">
                            {!loading && (
                                <div onClick={handleSubmit}>
                                    <ButtonNavigate width="w-[165px]" labelText="register now" />
                                </div>
                            )}
                            {loading && (
                                <div >
                                    <ButtonNavigate width="w-[165px]" labelText="Loading..." />
                                </div>
                            )}
                        </div>
                    </div>
                    </form>

                </div>
                {/* Modal Succes Get Freepass */}
                {status && <Succes open={status} custom={true} setOpen={setStatus} />}
            </div>

            <div className=' '>
                <img className='w-full object-contain' src={footer} alt="" />
            </div>

        </Layout>
    )
}

export default MovementChallenge