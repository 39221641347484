import React, { useState, useContext } from "react";
import * as studioData from "../../../mock/studios";
import ButtonNavigate from "../../common/buttons/ButtonNavigate";
import useFetch from "../../hooks/useFetch";
import { Link, navigate } from "gatsby";
import { LocationContext } from "../../context/LocationContext";
import { calculateDistance } from "../../utils/GetDistance";

function Studios() {
  const [currentLocation, setCurrentState] = useState("QLD");
  const myPosition = useContext(LocationContext).currentPos
  const { data } = useFetch({
    params: "studios",
    populate: "background,studio_features,state",
  });

  const setModal = useContext(LocationContext).setModal

  return (
    <div className="max-w-[1128px] mx-auto lg:pb-[100px] pb-[40px]">
      <div className="">
        <div className="flex relative gap-[35px] justify-center items-center border-b border-black">
          {Object.keys(studioData.places).map((place, idx) => (
            <div key={idx} className="pb-[20px] lg:min-w-[116px] relative "
              onClick={() => setCurrentState(place)}            >
              <p className="text-center buttons text-black cursor-pointer">{place}</p>
              <div className={`${currentLocation === place ? "bg-flexi h-[3px] w-full absolute -bottom-[1px]" : ""} `}></div>
            </div>
          ))}
        </div>
      </div>
      <div className="flex gap-6 py-[58px] lg:mx-0 mx-3  horizontalScroll">
        {data?.data?.filter((i) => i?.attributes?.state?.data?.attributes?.Name == currentLocation).map((studio, idx) => (
          <div key={idx} className="lg:min-w-[360px] min-w-[245px] bg-agate lg:py-6 py-3 px-5 rounded-[5px] text-black max-w-[360px]">
            <div className="flex justify-between">
              <p className="subheading">{studio?.attributes?.title}</p>
              {/* {myPosition?.lat !== undefined && (
                <p className="body">{calculateDistance(
                  myPosition?.lat,
                  myPosition?.lng,
                  studio?.attributes?.location?.coordinates[1],
                  studio?.attributes?.location?.coordinates[0])} km</p>
              )} */}
            </div>
            <img className="w-full py-6 lg:h-[315px] h-[140px] object-cover" src={studio?.attributes?.background?.data?.attributes?.url} />
            <div className="body h-[45px] flex gap-[13px]">
              <span className="">
                <svg className="inline-block " width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg"                >
                  <path d="M13.795 5.47968C13.4647 4.07433 12.7618 2.88858 11.7061 1.95438C10.6878 1.05348 9.49887 0.502228 8.17272 0.315478C6.64632 0.100378 5.19732 0.382528 3.86577 1.15338C2.59227 1.89093 1.64547 2.95293 1.05282 4.30968C0.530818 5.50443 0.369268 6.69558 0.572668 7.84983C0.766168 8.94738 1.24812 9.89283 1.69452 10.6727C2.50677 12.0915 3.49947 13.3448 4.36347 14.3829C4.95657 15.0957 5.57172 15.8166 6.16707 16.5141C6.43662 16.83 6.70797 17.1477 6.98022 17.4686L7.22322 17.7543L7.66602 17.2296C7.80822 17.0609 7.94502 16.8993 8.08182 16.7378C8.29737 16.4835 8.51427 16.2297 8.73117 15.9759C9.68742 14.8568 10.6765 13.6994 11.559 12.4794C12.2277 11.5547 13.0638 10.3104 13.5966 8.88348C14.0025 7.79403 14.0695 6.64878 13.795 5.47968ZM13.0008 8.66118C12.4945 10.017 11.689 11.2149 11.0437 12.1073C10.1766 13.3065 9.19602 14.4536 8.24787 15.5633C8.03052 15.8175 7.81317 16.0718 7.59717 16.3269C7.47297 16.4732 7.34922 16.6199 7.22142 16.7715C7.03062 16.5474 6.84027 16.3242 6.65037 16.1019C6.02937 15.3743 5.44302 14.6867 4.85217 13.977C4.00572 12.9596 3.03417 11.7338 2.24622 10.3572C1.82727 9.62508 1.37502 8.74128 1.19862 7.74003C1.01502 6.69648 1.15767 5.65743 1.63557 4.56438C2.17287 3.33408 3.03057 2.37153 4.18482 1.70328C5.12892 1.15653 6.13872 0.881578 7.19622 0.881578C7.48872 0.881578 7.78527 0.902728 8.08452 0.945028C9.28512 1.11423 10.362 1.61418 11.2854 2.43093C12.2407 3.27603 12.877 4.35108 13.1767 5.62548C13.422 6.66993 13.363 7.69143 13.0008 8.66118Z" fill="#000000" />
                  <path d="M7.22411 4.15771C5.96771 4.15771 4.94531 5.18011 4.94531 6.43651C4.94531 7.69291 5.96771 8.71531 7.22411 8.71531C8.48051 8.71531 9.50291 7.69291 9.50291 6.43651C9.50291 5.18011 8.48051 4.15771 7.22411 4.15771ZM7.22411 8.08036C6.31781 8.08036 5.58071 7.34326 5.58071 6.43696C5.58071 5.53066 6.31781 4.79356 7.22411 4.79356C8.13041 4.79356 8.86751 5.53066 8.86751 6.43696C8.86751 7.34326 8.13041 8.08036 7.22411 8.08036Z" fill="#000000" />
                </svg>
              </span>
              <span
                onClick={() => window.open(`https://www.google.com/maps/search/?api=1&query=${studio?.attributes?.location?.coordinates?.[1]},${studio?.attributes?.location?.coordinates?.[0]}`, '_blank')}
                className="cursor-pointer hover:underline">{studio?.attributes?.fullAddress}</span>
            </div>
            <div className="lg:py-3 py-2 min-h-[215px]">
              {studio?.attributes?.studio_features?.data?.map((plan, idx) => (
                <div key={idx} className="body flex items-center  mt-4 gap-[17px]">
                  <div>
                    <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg"                    >
                      <path d="M1.5 13L6.79289 7.70711C7.12623 7.37377 7.29289 7.20711 7.29289 7C7.29289 6.79289 7.12623 6.62623 6.79289 6.29289L1.5 1" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </div>
                  <span className="heading3">{plan?.attributes?.title}</span>
                </div>
              ))}
            </div>
            {/* <Link state={{ id: studio?.id }} to={`/studio/${studio?.attributes?.title?.toLowerCase().replace(" ", "-")}`}> */}
            <div onClick={() => localStorage.getItem('studio') !== null
              ? navigate(`/studio/${studio?.attributes?.title?.toLowerCase().replace(" ", "-")}`, { state: { id: studio?.id }, })
              : setModal(true)}>
              <ButtonNavigate isWhite={true} labelText="GO TO STUDIO" />
            </div>
            {/* </Link> */}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Studios;
