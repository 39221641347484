import studioImg from '../src/assets/images/HomePage/Studios/studioImg.png';
import team1 from '../src/assets/images/studio/team-mate1.png';
import team2 from '../src/assets/images/studio/team-mate2.png';
import team3 from '../src/assets/images/studio/team-mate3.png';

export const plan1 = [
  {
    summary: '3 months membership',
    details: '3 months membership will help you to save your price and ',
  },
  {
    summary: 'Any 2 classes / week',
    details: 'you can join any of 2 classes if you want',
  },
  {
    summary: 'access to any rebalance studio',
    details: 'free access and try any rebalance studio',
  },
];

export const places = {
  QLD: [
    {
      title: 'Stafford',
      img: studioImg,
      distance: '0.3',
      address: 'Unit 75/ 56 Lionsdale Road Canberra City, ACT 2615',
      plan: plan1,
    },
    {
      title: 'Stafford',
      img: studioImg,
      distance: '0.3',
      address: 'Unit 75/ 56 Lionsdale Road Canberra City, ACT 2615',
      plan: plan1,
    },
    {
      title: 'Stafford',
      img: studioImg,
      distance: '0.3',
      address: 'Unit 75/ 56 Lionsdale Road Canberra City, ACT 2615',
      plan: plan1,
    },
    {
      title: 'Stafford',
      img: studioImg,
      distance: '0.3',
      address: 'Unit 75/ 56 Lionsdale Road Canberra City, ACT 2615',
      plan: plan1,
    },
    {
      title: 'Stafford',
      img: studioImg,
      distance: '0.3',
      address: 'Unit 75/ 56 Lionsdale Road Canberra City, ACT 2615',
      plan: plan1,
    },
    {
      title: 'Stafford',
      img: studioImg,
      distance: '0.3',
      address: 'Unit 75/ 56 Lionsdale Road Canberra City, ACT 2615',
      plan: plan1,
    },
    {
      title: 'Stafford',
      img: studioImg,
      distance: '0.3',
      address: 'Unit 75/ 56 Lionsdale Road Canberra City, ACT 2615',
      plan: plan1,
    },
    {
      title: 'Stafford',
      img: studioImg,
      distance: '0.3',
      address: 'Unit 75/ 56 Lionsdale Road Canberra City, ACT 2615',
      plan: plan1,
    },
  ],
  NSW: [
    {
      title: 'Stafford',
      img: studioImg,
      distance: '0.3',
      address: 'Unit 75/ 56 Lionsdale Road Canberra City, ACT 2615',
      plan: plan1,
    },
    {
      title: 'Stafford',
      img: studioImg,
      distance: '0.3',
      address: 'Unit 75/ 56 Lionsdale Road Canberra City, ACT 2615',
      plan: plan1,
    },
    {
      title: 'Stafford',
      img: studioImg,
      distance: '0.3',
      address: 'Unit 75/ 56 Lionsdale Road Canberra City, ACT 2615',
      plan: plan1,
    },
    {
      title: 'Stafford',
      img: studioImg,
      distance: '0.3',
      address: 'Unit 75/ 56 Lionsdale Road Canberra City, ACT 2615',
      plan: plan1,
    },
  ],
  ACT: [
    {
      title: 'Stafford',
      img: studioImg,
      distance: '0.3',
      address: 'Unit 75/ 56 Lionsdale Road Canberra City, ACT 2615',
      plan: plan1,
    },
    {
      title: 'Stafford',
      img: studioImg,
      distance: '0.3',
      address: 'Unit 75/ 56 Lionsdale Road Canberra City, ACT 2615',
      plan: plan1,
    },
    {
      title: 'Stafford',
      img: studioImg,
      distance: '0.3',
      address: 'Unit 75/ 56 Lionsdale Road Canberra City, ACT 2615',
      plan: plan1,
    },
    {
      title: 'Stafford',
      img: studioImg,
      distance: '0.3',
      address: 'Unit 75/ 56 Lionsdale Road Canberra City, ACT 2615',
      plan: plan1,
    },
    {
      title: 'Stafford',
      img: studioImg,
      distance: '0.3',
      address: 'Unit 75/ 56 Lionsdale Road Canberra City, ACT 2615',
      plan: plan1,
    },
    {
      title: 'Stafford',
      img: studioImg,
      distance: '0.3',
      address: 'Unit 75/ 56 Lionsdale Road Canberra City, ACT 2615',
      plan: plan1,
    },
  ],
  VIC: [
    {
      title: 'Stafford',
      img: studioImg,
      distance: '0.3',
      address: 'Unit 75/ 56 Lionsdale Road Canberra City, ACT 2615',
      plan: plan1,
    },
    {
      title: 'Stafford',
      img: studioImg,
      distance: '0.3',
      address: 'Unit 75/ 56 Lionsdale Road Canberra City, ACT 2615',
      plan: plan1,
    },
    {
      title: 'Stafford',
      img: studioImg,
      distance: '0.3',
      address: 'Unit 75/ 56 Lionsdale Road Canberra City, ACT 2615',
      plan: plan1,
    },
    {
      title: 'Stafford',
      img: studioImg,
      distance: '0.3',
      address: 'Unit 75/ 56 Lionsdale Road Canberra City, ACT 2615',
      plan: plan1,
    },
    {
      title: 'Stafford',
      img: studioImg,
      distance: '0.3',
      address: 'Unit 75/ 56 Lionsdale Road Canberra City, ACT 2615',
      plan: plan1,
    },
    {
      title: 'Stafford',
      img: studioImg,
      distance: '0.3',
      address: 'Unit 75/ 56 Lionsdale Road Canberra City, ACT 2615',
      plan: plan1,
    },
    {
      title: 'Stafford',
      img: studioImg,
      distance: '0.3',
      address: 'Unit 75/ 56 Lionsdale Road Canberra City, ACT 2615',
      plan: plan1,
    },
    {
      title: 'Stafford',
      img: studioImg,
      distance: '0.3',
      address: 'Unit 75/ 56 Lionsdale Road Canberra City, ACT 2615',
      plan: plan1,
    },
  ],
};

export const ourTeams = [
  {
    image: team1,
    name: 'Anika Roth',
    desc: 'Originally from the UK, Beth began her sporting career as a competitive swimmer, competing at a national level across the UK. This fed into a keen interest in understanding the body’s full potential, resulting in studying a Bachelor in Sport and Exercise Science.',
    Qualifications: [
      'BSc Sport and Exercise Science',
      'Personal Trainer / Fitness Instructor',
      '11 years coaching experience',
    ],
  },
  {
    image: team2,
    name: 'Johanna Reeves',
    desc: 'Originally from the UK, Beth began her sporting career as a competitive swimmer, competing at a national level across the UK. This fed into a keen interest in understanding the body’s full potential, resulting in studying a Bachelor in Sport and Exercise Science.',
    Qualifications: [
      'BSc Sport and Exercise Science',
      'Personal Trainer / Fitness Instructor',
      '11 years coaching experience',
    ],
  },
  {
    image: team3,
    name: 'Cathy Pierse',
    desc: 'Originally from the UK, Beth began her sporting career as a competitive swimmer, competing at a national level across the UK. This fed into a keen interest in understanding the body’s full potential, resulting in studying a Bachelor in Sport and Exercise Science.',
    Qualifications: [
      'BSc Sport and Exercise Science',
      'Personal Trainer / Fitness Instructor',
      '11 years coaching experience',
    ],
  },
];
