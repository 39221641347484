import React, { useState, useEffect } from 'react'
import { useLocation } from '@reach/router'
function NewMembership() {
    const prevFill = useLocation()
    const [selected, setSelected] = useState<string>('')

    useEffect(() => {
        switch (prevFill?.state?.prefill?.toLowerCase()) {
            case 'express': setSelected('Express - Any 2 classes per week $44.95 per week')
                break;
            case 'flexi': setSelected('Flexi - Same as Ultimate - 28 days notice to cancel anytime (no contracts)')
                break;
            case 'ultimate': setSelected('Ultimate  3  classes unlocks unlimited access $55.95 per week')
                break;
            default:
                break;
        }
    }, [prevFill?.state?.prefill, selected])


    return (
        <div className=' overflow-hidden'>
            <script type="text/javascript" defer src="https://form.123formbuilder.com/embed/6360060.js" data-role="form" data-default-width="650px"></script>
            <div className='h-full overflow-hidden'>
                <iframe className='w-full h-[100vh]' id="contactform123" name="contactform123"
                    src={`https://form.123formbuilder.com/sf.php?s=123formbuilder-6360060&105528231=Ultimate 3 classes unlocks unlimited access $55.95 per week`}></iframe>

            </div>
        </div>
    )
}

export default NewMembership