import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';

const PrivateRoute = ({ component: Component, ...rest }) => {
  // useEffect(() => {
  //   if (!window.localStorage.getItem('rebalance') && location.pathname !== '/access') {
  //     navigate('/access');
  //   }
  // }, []);

  return <Component {...rest} />;
};

PrivateRoute.propTypes = {
  component: PropTypes.any.isRequired,
};

export default PrivateRoute;
