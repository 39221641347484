import React, { useState } from "react";

import GetYourFreePass from "../components/GetYourFreePass";
import HeroBanner from "../components/HomePage/HeroBanner";
import Layout from "../components/layout/Layout";
import Flexible from "../components/Membership/Flexible";
import TimeTableDetail from "../components/timetable/TimeTableDetail";
import useFetch from "../hooks/useFetch";
import ErrorBoundary from "../utils/ErrorBoundary";

function timetable() {
  const { data } = useFetch({
    params: "time-table",
    populate:
      "banner1.background,banner1.mobileImage,,banner2.background,banner3.background,banner4.background",
  });
  const [state, setState] = useState();

  // useEffect(() => {
  //   const hasReloaded = sessionStorage.getItem("hasReloaded")
  //   if (!hasReloaded) {
  //     sessionStorage.setItem("hasReloaded", true)
  //     window.location.reload()
  //   }
  // }, [])

  return (
    <ErrorBoundary>
      <Layout>
        <HeroBanner
          img={
            data?.data?.attributes?.banner1?.background?.data?.attributes?.url
          }
          title={data?.data?.attributes?.banner1?.title}
          bannerMobile={
            data?.data?.attributes?.banner1?.mobileImage?.data?.attributes?.url
          }
        />
        <div className="bg-apricot">
          <div className=" border-l-0 border-r-0 overflow-hidden px-2 py-[30px] relative ">
            <div className="flex top-1/2 transform -translate-y-1/2 absolute bg- text-[40px] text-white">
              <div className="flex w-[1440px] lg:gap-[100px] gap-7 items-center logo ">
                <span>
                  <p className="subheading whitespace-nowrap !font-[800] ">
                    barre
                  </p>
                </span>
                <span>
                  <p className="subheading whitespace-nowrap !font-[800] ">
                    pilates
                  </p>
                </span>
                <span>
                  <p className="subheading whitespace-nowrap !font-[800] ">
                    yoga
                  </p>
                </span>
                <span>
                  <p className="subheading whitespace-nowrap !font-[800] ">
                    reformer
                  </p>
                </span>
                <span>
                  <p className="subheading whitespace-nowrap !font-[800] ">
                    barre
                  </p>
                </span>
                <span>
                  <p className="subheading whitespace-nowrap !font-[800] ">
                    pilates
                  </p>
                </span>
                <span>
                  <p className="subheading whitespace-nowrap !font-[800] ">
                    yoga
                  </p>
                </span>
                <span>
                  <p className="subheading whitespace-nowrap !font-[800] ">
                    reformer
                  </p>
                </span>
              </div>
              <div className="flex w-[1440px] gap-[100px] items-center logo ml-[120px]">
                <span>
                  <p className="subheading whitespace-nowrap !font-[800] ">
                    barre
                  </p>
                </span>
                <span>
                  <p className="subheading whitespace-nowrap !font-[800] ">
                    pilates
                  </p>
                </span>
                <span>
                  <p className="subheading whitespace-nowrap !font-[800] ">
                    yoga
                  </p>
                </span>
                <span>
                  <p className="subheading whitespace-nowrap !font-[800] ">
                    reformer
                  </p>
                </span>
                <span>
                  <p className="subheading whitespace-nowrap !font-[800] ">
                    barre
                  </p>
                </span>
                <span>
                  <p className="subheading whitespace-nowrap !font-[800] ">
                    pilates
                  </p>
                </span>
                <span>
                  <p className="subheading whitespace-nowrap !font-[800] ">
                    yoga
                  </p>
                </span>
                <span>
                  <p className="subheading whitespace-nowrap !font-[800] ">
                    reformer
                  </p>
                </span>
                <span>
                  <p className="subheading whitespace-nowrap !font-[800] ">
                    pilates
                  </p>
                </span>
                <span>
                  <p className="subheading whitespace-nowrap !font-[800] ">
                    yoga
                  </p>
                </span>
                <span>
                  <p className="subheading whitespace-nowrap !font-[800] ">
                    reformer
                  </p>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="lg:w-[828px] w-[95%] md:py-[84px] py-[45px] mx-auto">
          <h2 className="heading text-center text-black sm:w-auto w-[332px] mx-auto">
            {data?.data?.attributes?.title}
          </h2>
          <p className=" text-black heading3 text-center sm:mt-[54px] mt-[30px]">
            {data?.data?.attributes?.description}
          </p>
        </div>
        <TimeTableDetail />
        <Flexible
          isShared={false}
          flexBackground="#C5C5FB"
          isShowBtn={true}
          textBtn="check our memberships"
          title={data?.data?.attributes?.banner2?.title}
          background={
            data?.data?.attributes?.banner2?.background?.data?.attributes?.url
          }
          description={data?.data?.attributes?.banner2?.customMetadata}
        />
        {/* <div className="relative">
          <img className="lg:h-[680px] h-[350px] object-cover w-full" src={data?.data?.attributes?.banner3?.background?.data?.attributes?.url} />
          <div className="absolute top-1/2  left-1/2 z-10 lg:w-[920px] w-[90%] flex justify-center items-center flex-col -translate-x-1/2  -translate-y-1/2   text-white">
            <h2 className="text-white heading text-center ">
              {data?.data?.attributes?.banner3?.title}
            </h2>
            <p className="my-[24px] text-center heading3">
              {data?.data?.attributes?.banner3?.description}
            </p>
            <ButtonNavigate labelText="Check our Blog" />
          </div>
        </div> */}
        {/* <FreePass background='#A57A71' color='#FFFDEF' isWhite={true}
          desc={data?.data?.attributes?.banner4?.description}
          img={data?.data?.attributes?.banner4?.background?.data?.attributes?.url} /> */}
        <GetYourFreePass
          color="#A57A71"
          bgShape="#A57A71"
          isWhite={true}
          title={data?.data?.attributes?.banner4?.title}
          desc={data?.data?.attributes?.banner4?.description}
          image={
            data?.data?.attributes?.banner4?.background?.data?.attributes?.url
          }
          vector={1}
        />
      </Layout>
    </ErrorBoundary>
  );
}

export default timetable;
